.profile-section {
  /* top: 90px;
  left: 24px; */
  width: 100%;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #314f7c1f;
  border-radius: 16px;
  opacity: 1;
  position: relative;
  margin-top: 39px;
}

.profile-register {
  /* text-align: left; */
  font: normal normal 600 14px/24px Titillium Web;
  letter-spacing: 0.28px;
  color: #1f2933;
  opacity: 1;
  position: absolute;
  top: 40px;
  left: 104px;
}

.guest-register-button {
  top: 66px;
  /* left: 104px; */
  width: 60px;
  height: 19px;
  background: #98694e 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 48px #314f7c14;
  border-radius: 16px;
  opacity: 1;
  position: absolute;
  border: none;
}

.register-left {
  left: 104px;
}

.register-right {
  right: 104px;
}

.guest-register-text {
  /* text-align: left; */
  font: normal normal 600 10px/8px Titillium Web;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  position: absolute;
  top: 6px;
}

.register-right .guest-register-text {
  right: 14px;
}

.register-left .guest-register-text {
  left: 14px;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-name {
  top: 18px;
  /* left: 105px; */
  height: 31px;
  /* text-align: left; */
  font: normal normal 600 16px Titillium Web;
  letter-spacing: 0.4px;
  color: #1f2933;
  opacity: 1;
  position: absolute;
}

.profile-right {
  right: 105px;
}

.profile-left {
  left: 105px;
}

.profile-number {
  top: 40px;
  /* left: 104px; */
  /* text-align: left; */
  font: normal normal 600 12px Titillium Web;
  letter-spacing: 0.28px;
  color: #1f2933;
  opacity: 1;
  position: absolute;
}

.numb-right {
  right: 104px;
  direction: ltr;
}

.numb-left {
  left: 104px;
}

.profile-email {
  top: 59px;
  /* left: 105px; */
  /* text-align: left; */
  font: normal normal 600 12px Titillium Web;
  letter-spacing: 0.28px;
  color: #1f2933;
  opacity: 1;
  position: absolute;
}

.email-right {
  right: 105px;
}

.email-left {
  left: 105px;
}

.right-contsectionainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.image-style {
  top: 15px;
  width: 68px;
  height: 68px;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
}

.img-right {
  right: 19px;
}

.img-left {
  left: 19px;
}

.profile-edit-button {
  background: 0% 0% no-repeat padding-box;
  border: none;
  top: 32px;
  /* right: 12px; */
  height: 25px;
  cursor: pointer;
  position: absolute;
}

.btn-left {
  left: 12px;
}

.btn-right {
  right: 12px;
}

.map-style {
  top: 212px;
  left: 39px;
  width: 16px;
  height: 15px;
  opacity: 1;
}
.inline-container {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 120px;
  left: 13px;
  cursor: pointer;
}

.inline-container p {
  text-align: left;
  font: normal normal 600 14px/10px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  position: relative;
  left: 10px;
  top: 8px;
}

.inline-container2 {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 150px;
  left: 13px;
  cursor: pointer;
}

.map-style {
  top: 212px;
  left: 39px;
  width: 16px;
  height: 15px;
  opacity: 1;
}

.inline-container2 p {
  text-align: left;
  font: normal normal 600 14px/10px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  position: relative;
  left: 10px;
  top: 8px;
}

.inline-container3 {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 180px;
  left: 13px;
  cursor: pointer;
}

.inline-container3 p {
  text-align: left;
  font: normal normal 600 14px/10px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  position: relative;
  left: 10px;
  top: 8px;
}

.inline-container4 {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 210px;
  left: 13px;
  cursor: pointer;
}

.inline-container4 p {
  text-align: left;
  font: normal normal 600 14px/10px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  position: relative;
  left: 10px;
  top: 8px;
}

.profile-footer {
  margin-top: 70px;
  position: relative;
}
.profile-footer .footer-container {
  bottom: 0px;
  position: fixed;
}
