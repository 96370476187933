/* Positioning of the facade container (left for mobile and right for desktop) */
.chat-facade-container {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    transition: all 0.3s ease; /* Smooth transition for position changes */
  }
  
  /* Mobile view: Facade on the left side */
  @media (max-width: 500px) {
    .chat-facade-container {
      left: 10px;
      right: unset;
    }
  }
  
  /* Hidden class to hide the facade once the chat has been loaded */
  .chat-facade-container.hidden {
    display: none;
  }
  
  /* Styling for the facade icon */
  .chat-facade-icon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff; */
    transition: opacity 0.3s ease; /* Smooth fade effect */
  }
  