.branches-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.branch-status-container {
  /* margin-right: 100px; */
}

.branch-info {
  width: 100%;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e9ed;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
}

.branch-info h1 {
  font: normal normal 600 12px/8px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
  position: absolute;
  top: 12px;
  width: 30%;
  line-height: 1;
}

.branch-info-ar h1 {
  right: 38px;
}

.branch-info-en h1 {
  left: 38px;
  text-align: left;
}

.branches-heading {
  width: 91%;
  display: flex;
  align-items: baseline;
  padding: 11px 0px 0px 13px;
  justify-content: center;
}

.branch-open-now {
  padding-top: 3px;
  text-align: center;
  width: 68px;
  height: 16px;
  font: 600 6px/8px Montserrat;
  letter-spacing: 0px;
  color: #00ce90;
  opacity: 1;
  box-shadow: 0px 24px 48px #314f7c14;
  border: 1px solid #00d49b;
  border-radius: 16px;
}

.branch-closed {
  padding-top: 3px;
  text-align: center;
  font: 600 6px/8px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #e02020;
  /* position: absolute; */
  box-shadow: 0px 24px 48px #314f7c14;
  border: 1px solid #e02020;
  border-radius: 16px;
  width: 68px;
  height: 16px;
  /* margin-right: 21px; */
  margin-left: auto;
}

.branch-closing-soon {
  padding-top: 3px;
  text-align: center;
  font: 600 6px/8px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #fa6400;
  box-shadow: 0px 24px 48px #314f7c14;
  border: 1px solid #fa6400;
  border-radius: 16px;
  width: 68px;
  height: 16px;
}

.branch-weekdays {
  text-align: center;
  font: normal normal normal 7px/8px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
  margin-top: 7px;
}

.branch-weekdaysHours {
  font: normal normal normal 7px/8px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
  margin-top: 7px;
}

.branch-weekends {
  text-align: center;
  font: normal normal normal 7px/8px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
  padding-left: 2px;
}

.branch-weekendsHours {
  text-align: center;
  font: normal normal normal 7px/8px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
}

.branch-icon {
  width: 27px;
  height: 27px;
  background: #98694e 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 9px;
  cursor: pointer;
}

.branch-icon img {
  margin-top: 4px;
}

.branch-icon-ar {
  margin-left: 5px;
  margin-right: 0px;
}

.branch-icon-ar img {
  margin-right: 5px;
}

.branch-icon-en img {
  margin-left: 5px;
}

.branch-timings {
  display: flex;
  /* Display children side by side */
  align-items: center;
  margin-left: -265px;
}

.time-ar {
  margin-right: -265px !important;
  margin-left: 0px !important;
}

.branch-hours {
  margin-top: 20px;
}

.branch-status-section {
  margin-top: -5px;
  /* width: 76%; */
  display: flex;
  justify-content: center;
}

.branch-status-ar {
  margin-right: 40px;
}

.branch-status-en {
  margin-left: 40px;
}
