.restaurantCard-container {
  display: grid;
  grid-template-columns: repeat(2, 1.2fr);
  gap: 10px;
  /* justify-items: center; */
  padding-top: 20px;
  width: 100%;
}

.restaurantCard-skeleton {
  cursor: pointer;
  border-radius: 12px;
  border: 3px solid rgba(147, 103, 78, 0); /* Match card's border */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9; /* Mimic card background */
  padding: 0;
}

.restaurantCard-skeleton .react-loading-skeleton {
  width: 99%; /* Placeholder width matching the actual image */
  border-radius: 10px; /* Rounded corners for the image */
}

.footer-text p {
  color: #959fa8;
  font-size: 10px;
  margin-bottom: 0px !important;
  padding-left: 10px;
}

.restaurantCard-border-delivery {
  border-color: rgba(147, 103, 78, 1) !important;
  border-radius: 12px;
  /* You can adjust the border color and width */
}

.restaurantCard-border-delivery:hover {
  border-color: rgba(147, 103, 78, 1) !important;
  border-radius: 12px;
  /* You can adjust the border color and width */
  /* transform: scale(1.08) !important; */
  transition: all 0.2s ease-in-out !important;
}

.addBorder {
  border-color: rgba(147, 103, 78, 1) !important;
}

/* .catering-bg {
  background-color: #93674e !important;
} */

.restaurantCard-border-near {
  border-color: rgb(79, 148, 138, 1) !important;
  border-radius: 12px;
  /* You can adjust the border color and width */
}

.catering-border {
  border-color: rgba(147, 103, 78, 1) !important;
}

.restaurantCard-border-near:hover {
  border-color: rgba(79, 148, 138, 1);
  border-radius: 12px;
  /* You can adjust the border color and width */
  /* transform: scale(1.08) !important; */
  transition: all 0.2s ease-in-out !important;
}

/* Style for the cards without a border */
.restaurantCard-disabled {
  background-color: rgba(255, 255, 255, 0.5); /* White color with 60% opacity */
  border: 2px solid rgba(255, 255, 255, 0.5); /* White border with 60% opacity */
  pointer-events: none; /* Disable interactions */
  opacity: 0.5; /* Make the card appear semi-transparent */
}

.restaurantCards {
  /* transition: transform 0.3s, box-shadow 0.3s; */
  cursor: pointer;
  border-radius: 12px;
  border: 3px solid rgba(147, 103, 78, 0);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 95%;
}

.restaurantCard-img {
  width: 99%;
  /* height: 74px; */
  object-fit: cover;
  margin-bottom: -6.5px;
  border: 1px solid white;
  border-radius: 10px;
}

.restaurantCards:hover {
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

/* .icon-row {
  margin-bottom: -11px;
  opacity: 1;
  margin-left: -2px;
}

.icon-row img {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1px 12px 1px 3px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  display: inline-block;
  margin-left: -4px;
  margin-bottom: 7px;
}

.icon-row-selected {
  margin-bottom: -11px;
  opacity: 1;
} */

/* .button-icons-styles-delivery {
  background-color: #93674e !important;
  padding: 1px 12px 1px 2px;
  margin-left: -2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.button-icons-styles-both {
  background-color: #5f934e !important;
}

.button-icons-styles-near {
  background-color: #4f948a !important;
  padding: 1px 12px 1px 2px;
  margin-left: -2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.button-icons-styles-none {
  padding: 1px 12px 1px 2px;
  margin-left: -2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
} */

/* .inner-svg-container {
  position: absolute;
  top: 2px;
  right: -6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

/* @media screen and (max-width: 500px) and (min-width: 410px) {
  .icon-row img {
    width: 16px !important;
    height: 16px !important;
  }
} */

/* .collapsed {
  opacity: 0;
}

.expanded {
  opacity: 1;
   width: auto !important; 
}
.expanded-width {
  width: auto !important;
}

.another-class {
  border: 3px solid rgba(147, 103, 78, 0) !important;
} */
