.form-style {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #c0cdd7 !important;
  border-radius: 12px !important;
  opacity: 1;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  height: 64px;
  color: #000000 !important;
}

.input-change input::placeholder,
.input-change textarea::placeholder {
  direction: rtl;
  text-align: right;
}

.form-style fieldset {
  border: none !important;
  outline: none !important;
}

.form-style .MuiSelect-select {
  padding: 0px !important;
  width: 86% !important;
  text-align: left;
  font: normal normal 16px/28px Titillium Web;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  cursor: pointer;
}

.form-style.MuiOutlinedInput-root {
  cursor: pointer;
}

.form-style.MuiInputBase-root {
  cursor: pointer;
}

.form-style:focus {
  outline: none;
  color: #959fa8;
}

.form-style::placeholder {
  text-align: left;
  font: normal normal bold 16px/28px Titillium Web;
  color: #959fa8;
  letter-spacing: 0px;
  opacity: 1;
}

.dropdown-placeholder {
  text-align: left;
  font: normal normal bold 16px/28px Titillium Web;
  letter-spacing: 0px;
  color: #959fa8;
  opacity: 1;
}

.select-menuitem {
  text-align: left;
  font: normal normal bold 16px/28px Titillium Web;
  letter-spacing: 0px;
  color: #959fa8;
  opacity: 1;
}

.error {
  color: #e02020 !important;
  border: 1px solid #e02020 !important;
}

.error-placeholder {
  color: #e02020 !important;
}

.error:focus {
  border: 1px solid #e02020 !important;
  color: #e02020 !important;
}

.error::placeholder {
  color: #e02020 !important;
}

.form-style-text-area {
  height: auto !important;
}

.area-main {
  position: relative;
}

.area-dropdown {
  position: absolute;
  right: 16px;
  top: 11px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 10px 30px rgba(49, 79, 124, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-text {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #c0cdd7;
  border-radius: 12px;
  opacity: 1;
  padding: 10px;
  width: 262px;
  margin-bottom: 10px;
  height: 140px;
}

.style-text::placeholder {
  text-align: left;
  font: normal normal bold 16px/28px Titillium Web;
  letter-spacing: 0px;
  color: #959fa8;
  opacity: 1;
  padding-bottom: 100px;
}

.MuiMenu-paper {
  max-height: 55% !important;
  scrollbar-width: none;
}

.MuiMenu-paper::-webkit-scrollbar {
  display: none;
}

#AdditionalDirection {
  resize: none;
  overflow-y: hidden;
  padding-bottom: 50px;
  scrollbar-width: none;
}

.container-style {
  margin-top: 10px;
}

.inputwrapper {
  position: relative;
}

.inputwrapper::after {
  content: attr(data-required);
  position: absolute;
  bottom: 0;
  right: 8px;
  transform: translateY(-100%);
  font: normal normal bold 16px/28px Titillium Web;
  color: #959fa8;
}

.inputwrapper-ar::after {
  left: 8px;
}

.inputwrapper-en::after {
  right: 8px;
}

.errorinputwrapper::after {
  color: #e02020;
}

.inputwrapper:has(> .form-style:not(:placeholder-shown))::after {
  content: "" !important;
}

/* @media screen and (max-width: 500px) and (min-width: 380px) {
  .container-style {
    margin-left: 30px;
  } 
} */
