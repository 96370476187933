.Nav_Image_Container {
  display: flex;
  position: relative;
  justify-content: center;
  height: 320px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* .Nav_Image_Container img {
  width: 100%;
  height: 375px;
} */

/* @media screen and (max-width: 500px) and (min-width: 380px) {
  .Nav_Image_Container img {
    width: 100%;
  }
  .Nav_Image_Container {
    margin-right: -50px !important;
  }
  .shopping_container {
    margin-right: 65px !important;
  }
} */

/* @media screen and (max-width: 350px) {
  .Nav_Image_Container img {
    width: 100%;
  }

  .shopping_container {
    margin-right: 12px !important;
  }
  .Nav_Image_Container {
    margin-left: -59px;
  }
  .back_button {
    margin-left: 65px !important;
  }
} */

.upper_navigation {
  min-width: 375px;
  width: 100%;
  max-width: 768px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  color: white;
}

.back_button {
  cursor: pointer;
  margin-left: 24px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 24px 48px #314f7c1f;
  /* padding: 8px; */
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back_button-icon {
  padding-top: 1px;
}

.back_button-icon img {
  width: 24px;
  height: 24px;
  margin-top: 5px;
}

.shopping_icon {
  padding-bottom: 1px;
}

.shopping_icon img {
  width: 24px;
  height: 24px;
  margin-top: 5px;
}

.shopping_container {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 24px 48px #314f7c1f;
  /* padding: 8px; */
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arabic_shop {
  margin-left: 24px;
}

.english_shop {
  margin-right: 24px;
}

.arabic_back {
  margin-right: 24px;
  rotate: 180deg;
}

.english_back {
  margin-left: 24px;
}
