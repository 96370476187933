.logo-home {
  color: #0d0d0d;
  margin: 0px 18px 0px 40px;
}

.beta {
  font-size: 12px;
  color: #1f2933;
  margin-left: 2px;
}

.remove-margin {
  margin: 0px !important;
}

@media screen and (max-width: 391px) and (min-width: 380px) {
  .logo-home {
    color: #0d0d0d;
    margin: 0px 24px 0px 68px;
  }
}

@media screen and (max-width: 450px) and (min-width: 420px) {
  .logo-home {
    color: #0d0d0d;
    margin: 0px 32.5px 0px 68px;
  }
}

.search-bar {
  padding: 15px;
  border-radius: 4px;
  color: black;
  width: 0;
  border: none;
}

.search-container.expanded {
  width: 250px;
  border-radius: 24px;
  padding-left: 17px;
  padding-top: 5px;
  height: 30px;
}

.search-container.expanded .search-bar {
  width: 150%;
}

.search-bar {
  border: none;
  background-color: transparent;
  outline: none;
  display: none;
}

.search-bar::placeholder {
  font: normal normal normal 15px Titillium Web;
}

.search-bar:focus {
  border: 1px solid #fdfcfc;
}

.element {
  transition: all 0.3s;
}

.search-expanded .element {
  transform: translateX(-100%);
}

.common-lng-btn {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 42px;
  width: 42px;
  cursor: pointer;
  box-shadow: 0px 24px 48px #314f7c1f;
}

.arabicicon-btn img {
  object-fit: contain;
  width: 11px;
  height: 20px;
}

.englishicon-btn img {
  object-fit: contain;
  width: 16px;
  height: 20px;
}

.icon-btn {
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 42px;
  width: 42px;
  cursor: pointer;
  box-shadow: 0px 24px 48px #314f7c1f;
}
.icon-btn-country {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 42px;
  width: 42px;
  cursor: pointer;
  box-shadow: 0px 24px 48px #314f7c1f;
}

.icon-btn img {
  object-fit: contain;
  width: 24px;
  height: 24px;
}

.arrowicon-btn {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 42px;
  width: 42px;
  cursor: pointer;
  box-shadow: 0px 24px 48px #314f7c1f;
}
/* .icon-btn-country img {
  width: 20px;
  height: 20px;
} */

.iconaccount-btn img {
  object-fit: contain;
  width: 16px;
  height: 19px;
  margin-top: -1px;
  margin-left: 1px;
}

.iconaccount-btn {
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 42px;
  width: 42px;
  cursor: pointer;
  box-shadow: 0px 24px 48px #314f7c1f;
  margin-left: -5px;
  margin-top: -3px;
}

.items-count {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #98694e;
  border-radius: 4px;
  top: -3px;
  right: -3px;
  font-size: 10px;
  text-align: center;
}
