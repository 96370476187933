.error_information_outer_container{
    width: 100%;
    background-color: #FCEAEA;
    display: flex;
    align-items: center;
}

.error_information_inner_container{
    width: 326px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.error_information_icon_container{
    padding-bottom: 3px;
}

.error_information_text_container{
    font-family: Titillium Web;
    font-size: 11px;
    font-weight: 400;
    color: #40484E;
}