.footer-style {
  /* text-align: left; */
  font: normal normal 600 14px Titillium Web !important;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  padding-top: 10px;
  /* padding-left: 10px; */
  width: 300px;
  display: block;
  line-height: 16px;
}

.terms-style {
  /* text-align: left; */
  font: normal normal 600 10px/20px Titillium Web;
  letter-spacing: 0px;
  color: #959fa8;
  opacity: 1;
  /* padding-left: 10px; */
  cursor: pointer;
}

.text-box {
  display: flex;
  float: right;
  margin-top: -60px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  z-index: 999; /* Set an appropriate z-index value */
}

/* @media screen and (max-width: 500px) and (min-width: 400px) {
  .text-box {
    position: absolute;
    top: 109%;
    left: 355px;
  }
}

@media screen and (max-width: 380px) and (min-width: 350px) {
  .text-box {
    top: 103%;
  }
}

@media screen and (max-width: 350px) {
  .text-box {
    left: 315px;
    top: 104%;
  }
} */
