.addresses-add-button {
  width: 326px;
  height: 56px;
  background: #98694e 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 16px;
  border: none;
  margin-top: 70px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.addresses-add-button:hover {
  background-color: rgb(106, 73, 54);
}

/* .addresses-add-button:hover span {
  color: #98694e;
} */

.addresses-add-button-text {
  text-align: center;
  font: normal normal 600 22px/24px Titillium Web;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}

.addressButton {
  justify-content: center;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1366px) {
  .addresses-add-button {
    height: 50px;
    margin-top: 35px;
  }
}

@media screen and (max-width: 500px) and (min-width: 355px) {
  .addresses-add-button {
    margin-top: 35px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 350px) {
  .addresses-add-button {
    margin-top: 165px;
  }
}
