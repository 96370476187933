.contact_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 32px; */
  padding: 10px 16px;
  border: none;
  color: white;
  width: 203.5px;
  height: 37.71px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  border-radius: 14px;
}

.contact_button_span img {
  margin-top: 4px;
  width: 15px;
  height: 14px;
}
.contact_button_text {
  margin-top: 4px;
  width: 100%;
}
