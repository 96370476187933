.detailsheaderheader {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.container-fluid {
  --bs-gutter-x: initial;
}

.detailsHeaderBackground {
  min-height: 309px;
}

.detailsheaderleft-icons {
  top: 9px;
  width: 24px;
  height: 24px;
  opacity: 1;
  position: relative;
}

.icons-left {
  left: 9px;
}

.icons-right {
  right: 9px;
}

.rotate {
  rotate: 180deg;
}

.detailsheaderright-icons {
  top: 8px;
  left: 9px;
  width: 24px;
  height: 24px;
  position: relative;
}

.detailsheadericon-style {
  top: 20px;
  position: relative;
  width: 42px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 50px #314f7c14;
  border-radius: 40px;
  opacity: 1;
  position: absolute;
}

.to-right {
  right: 24px;
}

.to-left {
  left: 24px;
}

.detailsheaderarrowicons-style {
  left: 24px;
  width: 42px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 50px #314f7c14;
  border-radius: 40px;
  opacity: 1;
  position: absolute;
  top: 20px;
}

.detailsheadercaption-container {
  top: -40px;
  margin-left: 230px;
  width: 121px;
  height: 35px;
  background: #026754 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  position: relative;
  z-index: 2;
}

.detailsheadercaption-text {
  top: 7px;
  left: 20px;
  width: 26px;
  height: 19px;
  text-align: center;
  font: normal normal 600 12px/12px Titillium Web;
  letter-spacing: 0px;
  color: #dad7d7;
  opacity: 1;
  position: absolute;
}

.detailsheadercaption-price {
  top: 6px;
  left: 20px;
  width: 100%;
  height: 50px;
  text-align: left;
  font: normal normal bold 20px/22px Titillium Web;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  position: absolute;
}
