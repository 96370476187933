.not-delivered .dialog-title {
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin: 26px auto 0px !important;
}

.not-delivered .dialog-title {
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin: 26px auto 0px !important;
}

.content {
  padding: 16.5px 15px 24px !important;
}

.text-detail {
  font: normal normal bold 16px/18px Titillium Web;
  color: #959fa8;
  margin-bottom: 38px;
  white-space: pre-line;
}

.disp-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.btn {
  border-radius: 12px !important;
  opacity: 1;
  width: 142px;
  line-height: 24px !important;
  padding: 3px 0px !important;
}

.btn span {
  font: normal normal 600 14px/24px Titillium Web;
}

.change-button {
  background: #ffffff 0% 0% no-repeat border-box;
  border: 1px solid #98694e !important;
}

.change-button span {
  color: #98694e;
}

.back-button {
  border: 1px solid #707070;
  background-color: #98694e !important;
}

.back-button span {
  color: #ffffff;
}

.items-not-delvered {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.15);
  width: 253px;
  padding: 6px;
  display: flex;
  align-items: center;
  margin: 0px auto 8px;
}

.items-image {
  width: 72px;
  height: 72px;
  border-radius: 12px;
  margin-right: 19px;
}

.items-image img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.item-heading {
  color: #1f2933;
  font-family: Titillium Web !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
}

.btn-navigate {
  display: flex;
  justify-content: space-around;
}

.dinein-btn-navigate {
  justify-content: space-evenly !important;
}

.dinein-btn-navigate button {
  width: 116px !important;
}

.common-st {
  font-family: Montserrat !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  padding: 4px 20px !important;
  margin-top: 31px !important;
}

.add-btn {
  border: 1px solid #98694e !important;
  background-color: #ffffff !important;
  color: #98694e !important;
  margin-top: 31px !important;
}

.go-to-btn {
  border: 1px solid #707070 !important;
  background-color: #98694e !important;
  color: #ffffff !important;
}

.continue-btn {
  padding: 4px 26px !important;
  border: 1px solid #707070 !important;
  background-color: #98694e !important;
  color: #ffffff !important;
  font-family: Titillium Web !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  margin-top: 31px !important;
}

.to-scroll {
  height: 190px;
  overflow-y: auto;
  padding-top: 10px;
  scrollbar-width: none;
}

.to-scroll::-webkit-scrollbar {
  display: none;
}

/* Pickup From Modal Style Starts */
.close-icon {
  padding: 0px !important;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -21px;
  right: 23px;
  cursor: pointer;
}

.pickup-from-common {
  font: normal normal 400 14px Titillium Web !important;
  color: #1f2933;
  display: block;
  text-align: center;
}

.pickup-from-info {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e9ed;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px 5px 15px;
  margin-bottom: 8px;
}

.pickup-br-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-basis: 45%;
}

.full-flex {
  flex-basis: 25%;
}

.pick-order {
  display: flex;
}

.pickup-restaurant-logo-dialog {
  width: 122px;
  height: 26px;
}

.pickup-restaurant-logo-dialog img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

/* Pickup From Modal Style Ends */

/*DineIn Modal Style Begins*/
.dineIn-from-common {
  font: bold 20px Titillium Web !important;
  color: #1f2933;
  display: block;
  text-align: center;
}

.dineIn-restaurant-logo-dialog img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.dineIn-grid-titles {
  font: bold 12px Titillium Web !important;
  color: #959fa8;
}
.dineIn-grid p {
  text-align: left;
}

.reservation-description {
  color: #707070;
  margin-bottom: 0px !important;
  line-height: 20px;
  white-space: pre-line;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .text-detail {
    margin-bottom: 28px;
  }

  .text-detail br {
    display: none;
  }

  .content {
    padding: 16.5px 20px 24px !important;
  }
}

/* Date Time Picker*/

.date-time-heading {
  display: flex;
  padding-top: 9px;
}

.date-time-heading button {
  width: 188px;
  border-bottom: 3px solid #e0ecea;
  border-radius: 0px;
  box-shadow: none;
  padding: 0px;
  font-size: 20px;
  line-height: 20px;
  color: #707070;
  font-weight: 600;
  padding-bottom: 7px;
  background-color: transparent !important;
}

.active-heading .date-active,
.active-heading .time-active {
  font-weight: bold;
  border-bottom-color: #026754;
}

.date-time-picker-dialog {
  padding-bottom: 17px !important;
}

.date-time-picker-dialog .MuiDateCalendar-root {
  height: auto;
}

.date-time-picker-dialog .MuiPickersCalendarHeader-root {
  margin: 11.5px 0px;
  min-height: 26px;
  max-height: 26px;
  padding: 0px;
  position: relative;
  justify-content: center;
}

.date-time-picker-dialog .MuiPickersCalendarHeader-labelContainer {
  font-weight: 600;
  line-height: 20px;
  color: #707070;
  margin: 0px 0px 0px 20px;
  pointer-events: none;
}

.date-time-picker-dialog .MuiPickersCalendarHeader-label {
  margin: 0px;
}

.date-time-picker-dialog .MuiPickersCalendarHeader-switchViewButton {
  display: none;
}

.date-time-picker-dialog .MuiPickersArrowSwitcher-button {
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #026754;
  border-radius: 8px;
  color: #ffffff;
}

.date-time-picker-dialog .MuiPickersArrowSwitcher-button:hover {
  background-color: #074237;
}

.date-time-picker-dialog .MuiPickersArrowSwitcher-button:first-child {
  left: 60px;
  top: 0;
}

.date-time-picker-dialog .MuiPickersArrowSwitcher-button:last-child {
  right: 60px;
  top: 0;
}

.date-time-picker-dialog .MuiDayCalendar-weekDayLabel,
.date-time-picker-dialog .MuiPickersDay-root {
  height: 29px;
  width: 29px;
  background-color: #e0ecea;
  border-radius: 8px;
  color: #707070;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 13px 0px 0px;
}

.date-time-picker-dialog .MuiDayCalendar-weekDayLabel:hover,
.date-time-picker-dialog .MuiPickersDay-root:hover {
  background-color: #e0ecea;
}

.date-time-picker-dialog .MuiDayCalendar-weekDayLabel:last-child,
.date-time-picker-dialog .MuiPickersDay-root:last-child {
  margin: 0px;
}

.date-time-picker-dialog .MuiDayCalendar-weekContainer {
  margin: 0px;
}

.date-time-picker-dialog .MuiPickersDay-root {
  margin-top: 9px !important;
}

.date-time-picker-dialog .MuiPickersDay-root:not(.Mui-selected) {
  border: none;
}

.date-time-picker-dialog .MuiPickersDay-root.Mui-selected {
  background-color: #e0ecea !important;
  border: 1px solid #026754 !important;
  color: #707070 !important;
  font-weight: 400 !important;
}

.date-time-picker-dialog .MuiPickersDay-root.Mui-disabled {
  background-color: rgba(240, 246, 245, 0.48) !important;
}

.date-time-picker-dialog .date-time-confirm {
  background-color: #026754;
  border-radius: 12px;
  width: 100%;
  height: 42px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.date-time-picker-dialog .date-time-confirm:hover {
  background-color: #074237;
}

.date-time-picker-dialog .time-header {
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.date-time-picker-dialog .time-header h4 {
  color: #707070;
  font-size: 16px;
  line-height: 20px;
  width: 79px;
  margin-right: 12px;
  text-decoration: underline;
}

.date-time-picker-dialog .MuiMultiSectionDigitalClock-root {
  justify-content: center;
  border: none;
  padding: 16px 0px 19px;
}

.date-time-picker-dialog .MuiMultiSectionDigitalClock-root ul {
  width: calc(79px + 2px);
  border: none;
  margin-right: 12px;
  padding-right: 2px;
  scrollbar-gutter: stable; 
}

.date-time-picker-dialog .MuiMultiSectionDigitalClock-root ul li {
  margin: 5px 0px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
  color: #707070;
  min-height: auto;
}

.date-time-picker-dialog .MuiMultiSectionDigitalClock-root ul li:hover {
  background-color: #f0f6f5;
  border-radius: 8px;
}

.date-time-picker-dialog ul:after {
  display: none;
}

.date-time-picker-dialog .MuiMultiSectionDigitalClock-root ul li.Mui-selected {
  background-color: #f0f6f5;
  border: 1px solid #026754;
  border-radius: 8px;
}

.date-time-picker-dialog .MuiMultiSectionDigitalClock-root ul:last-child {
  display: flex;
  align-items: center;
  flex-direction: column;
}
